/** POPIN **/
#layer {
    position: fixed;
    z-index: 200;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.7;
}
.popin {
    position: absolute;
    z-index: 300;
    left: 50%;
    top: 100px;
    width: 640px;
    font-size: 13px;
    margin-left: -320px;
    background: #f4f4f4;
}
.popin .close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}
.popin .content {
    padding: 15px;
}
.overlay-box .leftSide,
.overlay-box .rightSide {
    width: 48%;
}
.fieldText input {
    width: 100%;
    height: 30px;
    padding: 5px;
    font-size: 1.3em;
    border-radius: 3px;
    border: 1px solid #ccc;
    background: #fff;
}
.fieldSubmit input {
    display: inline-block;
    padding: 10px 30px;
    border: 1px solid black;
    background-color: #313131;
    background-image: -webkit-linear-gradient(#313131, #1c1c1c);
    background-image: linear-gradient(#313131, #1c1c1c);
    color: white;
    font-size: 1.3em;
    font-weight: bold;
    border-radius: 3px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
.formTitle {
    font-size: 1.4em;
    color: #d33b38;
    font-weight: 600;
}
.avantaeges_club {
    position: relative;
    padding-left: 25px;
}
.avantaeges_club .icon {
    position: absolute;
    left: 0;
    top: 0;
}
.avantaeges_club p {
    font-size: 14px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dcdcdc;
}
.avantaeges_club b {
    color: #bb383b;
}
#overlay-newsletter .bandeau_cadeau,
#overlay-newsletter-confirmation .bandeau_cadeau {
    min-height: 105px;
}
@media screen and (max-width: 480px) {
    .hidePhone { display: none; }
    .overlay-box .leftSide,
    .overlay-box .rightSide {
        width: 100%;
    }
    .overlay-box .footer {
        margin: 10px 0;
    }
    #overlay-newsletter .bandeau_cadeau,
    #overlay-newsletter-confirmation .bandeau_cadeau {
        min-height: 40px;
    }
}


